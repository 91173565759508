import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { View } from 'react-native';
import styleConstructor from './style';
import propTypesMap from '../../../propTypesMap';
const Dot = ({ theme, marked, disabled, inactive, color, today, selected }) => {
    const style = useRef(styleConstructor(theme));
    const dotStyle = [style.current.dot];
    if (marked) {
        dotStyle.push(style.current.visibleDot);
        if (today) {
            dotStyle.push(style.current.todayDot);
        }
        if (disabled) {
            dotStyle.push(style.current.disabledDot);
        }
        if (inactive) {
            dotStyle.push(style.current.inactiveDot);
        }
        if (selected) {
            dotStyle.push(style.current.selectedDot);
        }
        if (color) {
            dotStyle.push({ backgroundColor: color });
        }
    }
    return <View style={dotStyle}/>;
};
export default Dot;
export const propTypes = {
    theme: PropTypes.object,
    color: PropTypes.string,
    marked: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    inactive: PropTypes.bool,
    today: PropTypes.bool
};
propTypesMap.set(Dot, propTypes);
Dot.propTypes = propTypes;
